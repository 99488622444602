/* eslint-disable @typescript-eslint/no-misused-promises */
import './PlaylistConverter.css';
import ProvidersConfig from '../../api/ProvidersConfig';
import type Provider from '../../api/providers/Provider';
import { useEffect, useRef, useState } from 'react';
import { type Playlist } from '../../api/providers/Types';
import MessageStack, {
  MessageStackHandles,
} from '../../components/MessageStack/MessageStack';

const PlaylistConverter = (): JSX.Element => {
  const [fromMusicService, setFromMusicService] = useState<string>('');
  const [toMusicService, setToMusicService] = useState<string>('');
  const [musicProviders, setMusicProviders] = useState<Provider[]>([]);
  const [playlists, setPlaylists] = useState<Playlist[]>([]);
  const [selectedPlaylistId, setSelectedPlaylistId] = useState<string>('');
  const messageStackRef = useRef<MessageStackHandles>(null);

  useEffect(() => {
    const connectedProviders = ProvidersConfig.getConnectedProviders();
    if (connectedProviders.length < 2) {
      messageStackRef.current?.addMessage({
        message:
          'You need to connect at least two music services to use this feature',
        type: 'error',
        dismissable: true,
        route: '/settings',
      });
      return;
    }
    setMusicProviders([...connectedProviders]);
    if (connectedProviders.length > 0) {
      setFromMusicService(connectedProviders[0].prefix);
      if (connectedProviders.length > 1) {
        setToMusicService(connectedProviders[1].prefix);
      }
    }
  }, []);

  // Fetch and set playlists for the fromMusicService
  useEffect(() => {
    const fetchPlaylists = async (): Promise<void> => {
      const provider = ProvidersConfig.musicProviders.get(fromMusicService);
      if (provider != null) {
        const playlists = await provider.getPlaylists();
        setPlaylists(playlists);
        if (playlists.length > 0) {
          setSelectedPlaylistId(playlists[0].id);
        }
      }
    };

    if (fromMusicService !== '') {
      fetchPlaylists().catch((error) => {
        handleProviderError(error);
        return;
      });
    }
  }, [fromMusicService]);
  
  // Handle errors from the music providers
  const handleProviderError = (error: { message: string }) => {
    const customMessage = error.message.includes('https://developers.google.com')
      ? 'Reset your YouTube Music connexion in "Add music services". If it does not work, contact me (info in the page footer).'
      : error.message.includes('spotify.com')
        ? 'Reset your Spotify connexion in "Add music services". If it does not work, contact me (info in the page footer).'
        : error.message;
  
    messageStackRef.current?.addMessage({
      message: customMessage,
      type: 'error',
      dismissable: true,
    });
  };

  // Populate the select options with the available music providers
  const populateSelectOptions = (): JSX.Element[] => {
    return musicProviders.map((provider) => (
      <option key={provider.prefix} value={provider.prefix}>
        {provider.name}
      </option>
    ));
  };

  const handleFromMusicServiceChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    const newFromService = e.target.value;
    setFromMusicService(newFromService);
    setPlaylists([]);

    // Automatically adjust "to" service to avoid matching "from" service
    if (newFromService === toMusicService) {
      const nextService = musicProviders.find(
        (provider) => provider.prefix !== newFromService
      );
      if (nextService != null) setToMusicService(nextService.prefix);
    }
  };

  const handleToMusicServiceChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    setToMusicService(e.target.value);
  };

  const convertPlaylist = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): Promise<void> => {
    e.preventDefault();
    if (
      fromMusicService === '' ||
      toMusicService === '' ||
      selectedPlaylistId === ''
    ) {
      messageStackRef.current?.addMessage({
        message: 'Missing required fields',
        type: 'error',
        dismissable: true,
      });
      return;
    }
    const from = ProvidersConfig.musicProviders.get(fromMusicService);
    const to = ProvidersConfig.musicProviders.get(toMusicService);
    if (from === undefined || to === undefined) {
      messageStackRef.current?.addMessage({
        message: 'Invalid music service',
        type: 'error',
        dismissable: true,
      });
      return;
    }
    from
      .getPlaylist(selectedPlaylistId)
      .then(async (playlist) => {
        if (playlist === undefined) {
          throw new Error(`Error fetching playlist from ${from.name}`);
        }
        messageStackRef.current?.addMessage({
          message: `Successfully converted "${playlist.name}"`,
          type: 'success',
          dismissable: true,
        });
        return await to.createPlaylist(playlist);
      })
      .catch((error) => {
        handleProviderError(error);
        return;
      });
  };

  const handlePlaylistChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    setSelectedPlaylistId(e.target.value);
  };

  return (
    <div>
      <MessageStack ref={messageStackRef} />
      <h1>Convert playlists</h1>
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <fieldset>
          <label htmlFor="fromMusicService">From</label>
          <select
            id="fromMusicService"
            aria-label="Origin Music Service"
            value={fromMusicService}
            onChange={handleFromMusicServiceChange}
          >
            {populateSelectOptions()}
          </select>
          <label htmlFor="toMusicService">to</label>
          <select
            id="toMusicService"
            aria-label="Destination Music Service"
            value={toMusicService}
            onChange={handleToMusicServiceChange}
          >
            {populateSelectOptions()}
          </select>
          <br />
          <br />
          <label htmlFor="toMusicService">Select a playlist: </label>
          <select
            id="playlistSelector"
            aria-label="Playlist Selector"
            value={selectedPlaylistId}
            onChange={handlePlaylistChange}
          >
            {playlists.map((playlist) => (
              <option key={playlist.name} value={playlist.id}>
                {playlist.name}
              </option>
            ))}
          </select>
          <br />
          <br />
          <button id="convertBtn" type="submit" onClick={convertPlaylist}>
            Convert
          </button>
        </fieldset>
      </form>
    </div>
  );
};

export default PlaylistConverter;
