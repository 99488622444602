import ProvidersConfig from '../ProvidersConfig';
import type Provider from './Provider';
import { type Track, type Playlist } from './Types';

async function youtubeGetMusicCategoryId (): Promise<string> {
  try {
    const response: Response = await fetch('https://www.googleapis.com/youtube/v3/videoCategories?part=snippet&regionCode=US', {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${ProvidersConfig.getAccessToken(YoutubeMusic.prefix)}`
      }
    });
    const data = await response.json();
    if (!response.ok) throw new Error(String(data.error.message));
    const musicCategory = data.items.find((item: { snippet: { title: string } }) => item.snippet.title === 'Music');
    return musicCategory.id.toString();
  } catch (error: unknown) {
    throw new Error(`${String(error)} (fetching YouTube Music category ID failed)`);
  }
}

const YoutubeMusic: Provider = {
  name: 'YouTube Music',
  prefix: 'youtube_music',

  async authenticate (): Promise<void> { },

  async fetchAccessToken (): Promise<void> { },

  async refreshAccessToken (): Promise<void> { },

  async getPlaylists (): Promise<Playlist[]> {
    try {
      const response = await fetch('https://www.googleapis.com/youtube/v3/playlists?part=snippet&mine=true&maxResults=50', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${ProvidersConfig.getAccessToken(this.prefix)}`
        }
      });

      const data = await response.json();
      if (!response.ok) throw new Error(String(data.error.message));
      return data.items.map((playlist: { snippet: { title: string }, id: string }) => ({
        name: playlist.snippet.title,
        id: playlist.id
      }));
    } catch (error) {
      throw new Error(`${String(error)} (fetching YouTube Music playlists failed)`);
    }
  },

  clean (): void {
    localStorage.removeItem(`${this.prefix}_access_token`);
  },

  async getPlaylist (id: string): Promise<Playlist | undefined> {
    let playlist: Playlist | undefined;
    try {
      const response = await fetch('GET https://www.googleapis.com/youtube/v3/playlists' +
      '?part=snippet' +
      `&id=${encodeURIComponent(id)}` +
      '&maxResults=1',
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${ProvidersConfig.getAccessToken(this.prefix)}`
        }
      });

      const data = await response.json();
      if (!response.ok) throw new Error(String(data.error.message));
      playlist = {
        id: data.external_urls.spotify,
        name: data.name,
        description: data.description,
        tracks: []
      };
    } catch (error) {
      throw new Error(`${String(error)} (fetching YouTube Music playlist failed)`);
    }
    try {
      const response = await fetch('https://www.googleapis.com/youtube/v3/playlistItems' +
      '?part=snippet' +
      `&playlistId=${encodeURIComponent(id)}` +
      '&maxResults=50',
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${ProvidersConfig.getAccessToken(this.prefix)}`
        }
      });

      const data = await response.json();
      if (!response.ok) throw new Error(String(data.error.message));
      const tracks: Track[] = data.items.map((track: { snippet: { title: string, videoOwnerChannelTitle: string } }) => ({
        name: track.snippet.title,
        artist: track.snippet.videoOwnerChannelTitle
      }));

      playlist = {
        id: data.external_urls.spotify,
        name: data.name,
        description: data.description,
        tracks
      };
      console.log(playlist);
      return playlist;
    } catch (error) {
      throw new Error(`${String(error)} (fetching YouTube Music playlist tracks failed)`);
    }
  },

  async createPlaylist (playlist: Playlist): Promise<Playlist | undefined> {
    let newPlaylist: Playlist | undefined;
    try {
      // Create playlist
      const response = await fetch('https://www.googleapis.com/youtube/v3/playlists?part=snippet,status', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${ProvidersConfig.getAccessToken(this.prefix)}`
        },
        body: JSON.stringify({
          snippet: {
            title: playlist.name,
            description: playlist.description
          },
          status: {
            privacyStatus: 'private'
          }
        })
      });
      const data = await response.json();
      if (!response.ok) throw new Error(String(data.error.message));
      newPlaylist = {
        name: data.snippet.title,
        id: data.id,
        description: data.snippet.description,
        tracks: playlist.tracks
      };
    } catch (error) {
      throw new Error(String(error));
    }
    try {
      const videoIds = [];
      const youtubeMusicCategoryId: string = await youtubeGetMusicCategoryId();
      for (const track of playlist.tracks) {
        // Search for the track on YouTube Music
        const searchResponse = await fetch('https://www.googleapis.com/youtube/v3/search' +
                '?part=snippet' +
                `&q=${encodeURIComponent(track.name + ' ' + track.artist)}` +
                '&type=video' +
                `&videoCategoryId=${youtubeMusicCategoryId}` +
                '&maxResults=1', {
          headers: {
            Authorization: `Bearer ${ProvidersConfig.getAccessToken(this.prefix)}`
          }
        });
        const searchData = await searchResponse.json();
        if (!searchResponse.ok) throw new Error(String(searchData.error.message));
        if (searchData.items.length > 0) {
          videoIds.push(searchData.items[0].id.videoId);
        }
      }

      if (videoIds.length > 0) {
        for (const videoId of videoIds) {
          // Add track to the new playlist
          const addResponse = await fetch('https://www.googleapis.com/youtube/v3/playlistItems?part=snippet', {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${ProvidersConfig.getAccessToken(this.prefix)}`,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              snippet: {
                playlistId: newPlaylist?.id,
                resourceId: {
                  kind: 'youtube#video',
                  videoId
                }
              }
            })
          });
          const addData = await addResponse.json();
          if (!addResponse.ok) throw new Error(String(addData.error.message));
        }
      }
      return playlist;
    } catch (error) {
      throw new Error(`${String(error)} (processing YouTube Music tracks failed)`);
    }
  }
};

export default YoutubeMusic;
