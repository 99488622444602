import './Home.css';
import { Link } from 'react-router-dom';

const Home = (): JSX.Element => {
  return (
    <div>
      <h1>Welcome to playlist-converter.</h1>
      <p>
        You can connect to your music providers in <Link className="button" to="/settings">Add music services</Link> then
        head over to <Link className="button" to="/convert">Convert</Link>!
      </p>
    </div>
  );
};

export default Home;
