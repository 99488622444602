import Spotify from './providers/Spotify';
import YoutubeMusic from './providers/YoutubeMusic';
import type Provider from './providers/Provider';

const ProvidersConfig = {
  musicProviders: new Map<string, Provider>([
    ['spotify', Spotify],
    ['youtube_music', YoutubeMusic]
  ]),

  /**
     * Gets the access token for a music provider
     * @param name Music provider name
     * @returns the access token
     */
  getAccessToken (name: string): string {
    const provider = this.musicProviders.get(name);
    if (provider == null) return '';
    const token = localStorage.getItem(`${provider.prefix}_access_token`);
    if (token == null) return '';
    return token;
  },

  /**
     * Sets the new access token to localStorage
     * @param name Music provider name
     * @param token the access token
     * @returns void
     */
  setAccessToken (name: string, token: string): void {
    const provider = this.musicProviders.get(name);
    if (provider == null) {
      throw new Error(`Invalid provider name: ${name}`);
    }
    localStorage.setItem(`${provider.prefix}_access_token`, token);
  },

  getConnectedProviders (): Provider[] {
    const providers: Provider[] = [];
    for (const provider of this.musicProviders.values()) {
      if (this.getAccessToken(provider.prefix) !== '') {
        providers.push(provider);
      }
    }
    return providers;
  }
};

export default ProvidersConfig;
