// App.tsx
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home/Home';
import PlaylistConverter from './pages/PlaylistConverter/PlaylistConverter';
import Settings from './pages/Settings/Settings';
import Navbar from './components/Navbar/Navbar';
import { GoogleOAuthProvider } from '@react-oauth/google';

function App() {
  if (!process.env.REACT_APP_YOUTUBE_MUSIC_API_KEY) {
    return (
      <div className="app-content">
        <h1>Missing API Key</h1>
        <p>
          It seems that the API key for YouTube Music is missing. Please add it
          to the .env file and restart the app.
        </p>
      </div>
    );
  }
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_YOUTUBE_MUSIC_API_KEY}>
      <Router>
        <div className="background-shapes">
          <div className="circle large"></div>
          <div className="circle medium"></div>
          <div className="circle small"></div>
        </div>
        <div className="glass-background"></div>
        <div className="app-content">
          <Navbar />
          <div className="pages">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/convert" element={<PlaylistConverter />} />
              <Route path="/settings" element={<Settings />} />
            </Routes>
          </div>
        </div>
      </Router>
    </GoogleOAuthProvider>
  );
}

export default App;
