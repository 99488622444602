import YoutubeMusic from '../../api/providers/YoutubeMusic';
import ProviderButton from '../../components/ProviderButton/ProviderButton';
import './Settings.css';
import { useEffect } from 'react';
import Spotify from '../../api/providers/Spotify';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ProvidersConfig from '../../api/ProvidersConfig';
import { useGoogleLogin } from '@react-oauth/google';

const Settings = () => {
  const [isConnectedYoutubeMusic, setIsConnectedYoutubeMusic] = useState(!!ProvidersConfig.getAccessToken(YoutubeMusic.prefix));
  const [isConnectedSpotify, setIsConnectedSpotify] = useState(!!ProvidersConfig.getAccessToken(Spotify.prefix));
  const navigate = useNavigate();

  useEffect(() => {
    const spotifyURLChecks = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const error = urlParams.get('error');
      if (error) {
        console.error('Error authenticating with Spotify:', error);
        Spotify.clean();
        setIsConnectedSpotify(false);
        navigate('/settings', { replace: true });
        return;
      }
      const code = urlParams.get('code');
      if (code) {
        Spotify.fetchAccessToken(code);
        setIsConnectedSpotify(true);
        navigate('/settings', { replace: true });
      }
    };
    spotifyURLChecks();
  }, [navigate]);

  const youtubeLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      console.log(tokenResponse);
      ProvidersConfig.setAccessToken(YoutubeMusic.prefix, tokenResponse.access_token);
      setIsConnectedYoutubeMusic(true);
      // const response = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
      //   headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
      // });
      // const userInfo = await response.json();
  
      // console.log(userInfo);
    },
    onError: errorResponse => console.log(errorResponse),
  });
  
  return (
    <div>
      <h1>Connect to your music apps</h1>
      <div className="provider-buttons-container">
        <ProviderButton
          providerName={YoutubeMusic.name}
          isConnected={isConnectedYoutubeMusic}
          toggleConnect={async () => {
            if (ProvidersConfig.getAccessToken(YoutubeMusic.prefix) !== '') {
              YoutubeMusic.clean();
              setIsConnectedYoutubeMusic(false);
            } else {
              youtubeLogin();
              await YoutubeMusic.authenticate();
            }
          }}
        />
        <ProviderButton
          providerName={Spotify.name}
          isConnected={isConnectedSpotify}
          toggleConnect={async () => {
            if (ProvidersConfig.getAccessToken(Spotify.prefix) !== '') {
              Spotify.clean();
              setIsConnectedSpotify(false);
            } else {
              await Spotify.authenticate();
            }
          }}
        />
      </div>
    </div>
  );
};

export default Settings;
