// MessageStack.tsx
import { useImperativeHandle, useState, forwardRef } from 'react';
import Message, { MessageProps } from '../Message/Message'; // Adjust the path as needed

interface MessageWithId extends MessageProps {
  id: number;
}

export interface MessageStackHandles {
  addMessage: (params: MessageProps) => void;
  removeMessage: (id: number) => void;
}

const MessageStack = forwardRef<MessageStackHandles, object>((props, ref) => {
  const [messages, setMessages] = useState<MessageWithId[]>([]);

  const addMessage = ({
    message,
    type = 'info',
    dismissable = true,
    onDismiss,
    buttonLabel,
    href,
    route,
    left = 15,
    bottom = 15
  }: MessageProps) => {
    setMessages((currentMessages) => [
      ...currentMessages,
      { id: Date.now(), message, type, dismissable, onDismiss, buttonLabel, href, route, left, bottom},
    ]);
  };

  const removeMessage = (id: number) => {
    setMessages((currentMessages) =>
      currentMessages.filter((msg) => msg.id !== id)
    );
  };

  useImperativeHandle(ref, () => ({
    addMessage,
    removeMessage
  }));

  return (
    <>
      {messages.map(({ id, message, type, dismissable }, index) => (
        <Message
          key={id}
          message={message}
          type={type}
          dismissable={dismissable}
          onDismiss={() => removeMessage(id)}
          left={5*index + 15}
          bottom={5*index + 15}
        />
      ))}
    </>
  );
});

MessageStack.displayName = 'MessageStack';

export default MessageStack;
