import ProvidersConfig from '../ProvidersConfig';
import Provider from './Provider';
import { Playlist, Track } from './Types';

const Spotify: Provider = {
  name: 'Spotify',
  prefix: 'spotify',

  async authenticate(): Promise<void> {
    const APIKey = process.env.REACT_APP_SPOTIFY_API_KEY;
    const scope = process.env.REACT_APP_SPOTIFY_SCOPE;
    if (!APIKey || !scope) {
      throw new Error('Spotify API key or scope not set');
    }
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const randomValues = crypto.getRandomValues(new Uint8Array(64));
    const randomString = randomValues.reduce((acc, x) => acc + possible[x % possible.length], '');

    const code_verifier = randomString;
    const data = new TextEncoder().encode(code_verifier);
    const hashed = await crypto.subtle.digest('SHA-256', data);

    const code_challenge_base64 = btoa(String.fromCharCode(...new Uint8Array(hashed)))
      .replace(/=/g, '')
      .replace(/\+/g, '-')
      .replace(/\//g, '_');

    window.localStorage.setItem(`${this.prefix}_code_verifier`, code_verifier);

    const authUrl = new URL('https://accounts.spotify.com/authorize');
    const params = {
      response_type: 'code',
      client_id: APIKey,
      scope: scope,
      code_challenge_method: 'S256',
      code_challenge: code_challenge_base64,
      redirect_uri: window.location.href.split('?')[0],
    };

    authUrl.search = new URLSearchParams(params).toString();
    window.location.href = authUrl.toString();
  },

  async fetchAccessToken(code: string): Promise<void> {
    const APIKey = process.env.REACT_APP_SPOTIFY_API_KEY;
    const codeVerifier = localStorage.getItem(`${Spotify.prefix}_code_verifier`);
    if (!APIKey || !codeVerifier) {
      throw new Error('Spotify API key or code verifier not set');
    }

    const payload = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        client_id: APIKey,
        grant_type: 'authorization_code',
        code,
        redirect_uri: window.location.href.split('?')[0],
        code_verifier: codeVerifier,
      }),
    };

    const body = await fetch(new URL('https://accounts.spotify.com/api/token'), payload);
    const response = await body.json();
    if (!body.ok) throw new Error(response.error_description);

    ProvidersConfig.setAccessToken(this.prefix, response.access_token);
    localStorage.setItem(`${this.prefix}_refresh_token`, response.refresh_token);
    localStorage.setItem(`${this.prefix}_expiry_time`, (new Date().getTime() + response.expires_in * 1000).toString());
  },

  async refreshAccessToken(): Promise<void> {
    const refreshToken = localStorage.getItem(`${this.prefix}_refresh_token`);
    if (!refreshToken) {
      throw new Error('Spotify refresh token not set: please authenticate again');
    }

    const APIKey = process.env.REACT_APP_SPOTIFY_API_KEY;
    if (!APIKey) {
      throw new Error('Spotify API key not set');
    }

    const payload = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        client_id: APIKey,
        grant_type: 'refresh_token',
        refresh_token: refreshToken
      }),
    };

    try {
      const response = await fetch('https://accounts.spotify.com/api/token', payload);
      const data = await response.json();
      if (!response.ok) throw new Error(data.error_description);

      ProvidersConfig.setAccessToken(this.prefix, data.access_token);

      const expiresIn = data.expires_in;
      const expiryTime = new Date().getTime() + expiresIn * 1000;
      localStorage.setItem(`${this.prefix}_expiry_time`, expiryTime.toString());
      localStorage.setItem(`${this.prefix}_refresh_token`, data.refresh_token);
    } catch (error) {
      throw new Error(`${error} (refreshing Spotify access token failed)`);
    }
  },

  async getPlaylists(): Promise<Playlist[]> {
    const expiryTime = parseInt(localStorage.getItem(`${this.prefix}_expiry_time`) || '0');
    if (new Date().getTime() >= expiryTime) {
      await this.refreshAccessToken();
      console.info('Access token refreshed');
    }
    try {
      const response = await fetch('https://api.spotify.com/v1/me/playlists', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${ProvidersConfig.getAccessToken(this.prefix)}`,
        }
      });

      const data = await response.json();
      if (!response.ok) throw new Error(data.error.message);

      return data.items.map((playlist: { name: string; description: string; id: string; }) => ({
        id: playlist.id,
        name: playlist.name,
        description: playlist.description,
      }));
    } catch (error) {
      throw new Error(`${error} (fetching Spotify playlists failed)`);
    }
  },

  async getPlaylist(id: string): Promise<Playlist | undefined> {
    try {
      const response = await fetch(`https://api.spotify.com/v1/playlists/${id}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${ProvidersConfig.getAccessToken(this.prefix)}`,
        }
      });

      const data = await response.json();
      console.log('getPlaylist spotify: ', data);
      if (!response.ok) throw new Error(data.error.message);
      const tracks: Track[] = data.tracks.items.map((track: { track: { name: string; artists: { name: string; }[] } }) => ({
        name: track.track.name,
        artist: (track.track.artists || []).map((artist: { name: string; }) => artist.name).join(', '),
      }));

      const playlist: Playlist = {
        id: data.external_urls.spotify,
        name: data.name,
        description: data.description,
        tracks: tracks,
      };
      console.log('got playlist: ', playlist);
      return playlist;
    } catch (error) {
      throw new Error(`${error} (fetching Spotify playlist failed)`);
    }
  },

  async createPlaylist(playlist: Playlist): Promise<Playlist | undefined> {
    let userId: string;
    try {
      // Get the user ID
      const response = await fetch('https://api.spotify.com/v1/me', {
        headers: {
          Authorization: `Bearer ${ProvidersConfig.getAccessToken(this.prefix)}`,
        },
      });
      const data = await response.json();
      if (!response.ok) throw new Error(data.error.message);
      userId = data.id;
    } catch (error) {
      throw new Error(`${error} (fetching Spotify user ID failed)`);
    }
    let playlistId: string;
    try {
      // Create a new playlist
      const response = await fetch(`https://api.spotify.com/v1/users/${userId}/playlists`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${ProvidersConfig.getAccessToken(this.prefix)}`,
        },
        body: JSON.stringify({
          name: playlist.name,
          public: true,
          collaborative: false,
          description: playlist.description
        })
      });
      const data = await response.json();
      if (!response.ok) throw new Error(data.error.message);
      playlistId = data.id;
    } catch (error) {
      throw new Error(`${error} (creating Spotify playlist failed)`);
    }
    // Add tracks to playlist
    let spotifyTracksURIsCSV = '';
    try {
      for (const track of playlist.tracks) {
        const trackResponse = await fetch(`https://api.spotify.com/v1/search?q=${encodeURIComponent(track.name + ' ' + track.artist)}&type=track&limit=1`, {
          headers: {
            'Authorization': `Bearer ${ProvidersConfig.getAccessToken(this.prefix)}`,
          },
        });
        const data = await trackResponse.json();
        if (!trackResponse.ok) throw new Error(data.error.message);
        if (data.tracks.items[0].uri !== undefined && data.tracks.items[0].uri.startsWith('spotify:track:')) {
          spotifyTracksURIsCSV += `${data.tracks.items[0].uri},`;
        }
      }
    } catch (error) {
      throw new Error(`${error} (searching for tracks in Spotify failed)`);
    }
    try {
      // add tracks to the playlist
      const response = await fetch(`https://api.spotify.com/v1/playlists/${playlistId}/tracks`, {
        headers: {
          'Authorization': `Bearer ${ProvidersConfig.getAccessToken(this.prefix)}`,
        },
        body: JSON.stringify({
          uris: spotifyTracksURIsCSV,
        })
      });
      const data = await response.json();
      console.log(data);
      return playlist;
    } catch (error) {
      throw new Error(`${error} (adding tracks to Spotify playlist failed)`);
    }
  },

  clean(): void {
    localStorage.removeItem(`${this.prefix}_access_token`);
    localStorage.removeItem(`${this.prefix}_code_verifier`);
    localStorage.removeItem(`${this.prefix}_refresh_token`);
  },

};

export default Spotify;