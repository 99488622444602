import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Message.css';

export interface MessageProps {
  message: string
  type?: MessageType
  dismissable?: boolean
  onDismiss?: () => void
  buttonLabel?: string
  href?: string
  route?: string
  left?: number
  bottom?: number
}

export type MessageType = 'error' | 'success' | 'info'

const Message: React.FC<MessageProps> = ({
  message,
  type,
  dismissable = true,
  onDismiss,
  buttonLabel,
  href,
  route,
  left = 15,
  bottom = 15,
}: MessageProps) => {
  const navigate = useNavigate();

  // Function to create JSX elements for links
  const createMessageContent = (msg: string) => {
    const parts = msg.split(/(https:\/\/\S+)/g);
    return parts.map((part, index) => {
      if (part.match(/^https:\/\/\S+$/)) {
        return <a href={part} key={index} target="_blank" rel="noopener noreferrer">{part.replace(/^https:\/\//, '')}</a>;
      } else {
        return part;
      }
    });
  };

  return (
    <div className={`message-container ${type}`} style={{left: `${left}px`, bottom: `${bottom}px`}}>
      <p>{createMessageContent(message)}</p>
      {(buttonLabel != null) && (href != null)
        ? <Link to={href} className="message-button">{buttonLabel}</Link>
        : (route != null)
          ? <button onClick={() => { navigate(route); }} className="message-button">{buttonLabel}</button>
          : null}
      {dismissable && (
        <button onClick={onDismiss} className="dismiss-button">×</button>
      )}
    </div>
  );
};

export default Message;
