// ProviderButton.tsx
import React from 'react';
import './ProviderButton.css'; // Make sure to import the stylesheet

interface ProviderButtonProps {
  providerName: string
  isConnected: boolean
  toggleConnect: () => void
}

const ProviderButton: React.FC<ProviderButtonProps> = ({ providerName, isConnected, toggleConnect }) => {
  return (
    <button className={`provider-button ${isConnected ? 'connected' : 'disconnected'}`} onClick={toggleConnect}>
      {isConnected ? `Connected to ${providerName}` : `Connect to ${providerName}`}
    </button>
  );
};

export default ProviderButton;
